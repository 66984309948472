#Header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  color: #fff;
  padding: 0 30px;
  z-index: 100;
}

#Header .header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  z-index: 1;
}

#Header .header .logo {
  width: 46px;
  height: 40px;
  z-index: 100;
}

/* burger */
#Header .border {
  border: 1px solid #fff;
}

#menuToggle {
  display: block;
  position: absolute;
  top: 37px;
  right: -1px;
  z-index: 100;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 8px;
  width: 43px;
  height: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#menuToggle input {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 28px;
  height: 1px;
  margin-bottom: 10px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(1) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(-45deg) translate(3px, 4px);
  background: #fff;
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  transform: rotate(45deg) translate(4px, -2px);
}

#menuToggle .size_up {
  width: 35px;
}

/* slide */
#Header .side-menu {
  position: absolute;
  right: -100vw;
  transition: 0.5s;
  transition: height 0s ease;
}

#Header .side-menu-active {
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  font-size: 160px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  background-position: 99% 100px;
  z-index: 99;
  background-image: url("/images/header/menu_back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#Header .side-menu-active > .side-menu-wrap {
  width: 100%;
  height: 100%;
}

#Header .side-menu-active > .side-menu-wrap > ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Header .side-menu-active > .side-menu-wrap > ul li a {
  text-decoration: none;
}

#Header .font_green {
  color: #1aee88 !important;
}

#Header .font_white {
  color: #fff !important;
}

#Header .font_purple {
  color: #6f2cff !important;
}

#Header .font_green:hover {
  color: #1aee88;
  text-shadow: 0 0 5px #1aee88;
}

#Header .font_white:hover {
  color: #fff;
  text-shadow: 0 0 5px #fff;
}

#Header .font_purple:hover {
  color: #6f2cff;
  text-shadow: 0 0 5px #6f2cff;
}

#Header .sns {
  position: absolute;
  right: 28px;
  bottom: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

#Header .sns div {
  width: 43px;
  height: 43px;
  margin-bottom: 10px;
  display: flex;
}

@media screen and (max-width: 1000px) {
  #Header .side-menu-active {
    font-size: 70px;
  }

  #Header .sns {
    display: block;
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    right: 0px;
    height: auto;
  }

  #Header .sns div {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  #Header {
    height: 62px;
  }

  #Header .side-menu-active {
    font-size: 55px;
  }

  #menuToggle {
    top: 24px;
    right: 1px;
    width: 37px;
    height: 38px;
  }

  #menuToggle span {
    width: 26px;
    margin-bottom: 9px;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    transform: rotate(45deg) translate(2px, -4px);
  }
}

@media screen and (max-width: 468px) {
  #menuToggle span {
    width: 23px;
    margin-bottom: 8px;
  }
}
