#Section4 {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/images/section4/background1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: all 0.2s linear;
}

#Section4 .wrap {
  width: 80%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#Section4 .wrap > div {
  width: 25%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  text-align: center;
}

#Section4 .wrap .title {
  font-size: 25px;
  letter-spacing: -0.75px;
}

#Section4 .wrap .info {
  font-size: 20px;
  letter-spacing: -1px;
  max-width: 368px;
  line-height: 1.3;
}

#Section4 .view {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/images/section4/view_white.png);
}

#Section4 .gray {
  background-color: rgba(0, 0, 0, 0.6);
}

#Section4 .hover,
#Section4 .view:hover,
#Section4 .view:hover .title {
  color: #1aee88;
}

#Section4 .backImg {
  background-image: url(/images/section4/view_green.png) !important;
}

#Section4 .react-reveal {
  cursor: pointer;
  /* padding: 20px 0; */
}

@media screen and (max-width: 1254px) {
  #Section4 .wrap {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  #Section4 .view {
    display: none;
  }

  #Section4 .wrap > div {
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  #Section4 .wrap > div:nth-child(3) {
    border: none;
  }

  #Section4 .title {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  #Section4 {
    padding: 0px;
  }

  #Section4 .wrap .title {
    font-size: 21.5px;
    margin-bottom: 20px;
  }

  #Section4 .wrap .info {
    font-size: 18px !important;
    width: calc(100% - 100px);
  }
}
