#Main {
  width: 100%;
}

#Main .section {
  height: 100vh;
}

/* section same css */
#Main .fp-tableCell {
  display: block;
}

video {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gray {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

/* web quick menu name, sns */
#Main > .name {
  width: 43px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 28px;
  z-index: 2;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
}

#Main > .sns {
  position: absolute;
  right: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

#Main > .sns div {
  width: 43px;
  height: 43px;
  margin-bottom: 10px;
}

#Main > .sns div > img {
  width: 100%;
}

#Main > .name,
#Main > .sns {
  bottom: 40%;
  height: 159px;
}

@media screen and (max-width: 768px) {
  #Main > .name,
  #Main > .sns {
    display: none;
  }
}
