#Section5 {
  width: 100%;
  height: 100%;
  position: relative;
  background: #0d0d0d;
  color: #fff;
}

#Section5 .title {
  padding: 95px 0 70px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* swiper */
#Section5 .contents {
  width: 500px;
  position: relative;
}

#Section5 .contents .date {
  position: absolute;
  top: 36%;
  left: -30px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: #1aee88;
  font-size: 12px;
}

#Section5 .contents .info {
  font-size: 25px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-top: 20px;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  box-sizing: border-box;
  cursor: pointer;
}

.swiper-slide-active {
  padding: 0 150px !important;
}

.swiper-button-prev,
.swiper-button-next {
  border: 1px;
  width: 100px;
  height: 100px;
  z-index: 100;
  background-image: url(/images/section5/arrow.png);
  color: transparent !important;
}

.swiper-button-prev {
  left: 166px;
  top: 40%;
}

.swiper-button-next {
  right: 185px;
  top: 40%;
  transform: rotate(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-image: url(/images/section5/arrow_green.png);
}

.gradient {
  min-width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.swiper-slide-next .gradient,
.swiper-slide-prev .gradient {
  background-color: rgba(13, 13, 13, 0.8);
}

.swiper-slide-next .date,
.swiper-slide-prev .date {
  color: rgba(255, 255, 255, 0.1) !important;
}

.image-gallery {
  display: none;
}

@media screen and (max-width: 1725px) {
  .swiper-slide-active {
    padding: 0 80px !important;
  }

  .swiper-button-prev {
    left: 110px;
  }

  .swiper-button-next {
    right: 133px;
  }
}

@media screen and (max-width: 1650px) {
  #Section5 .title {
    padding: 95px 0 114px 0;
  }

  .swiper-slide-active {
    padding: 0 110px !important;
  }

  #Section5 .contents {
    width: 400px;
  }
}

@media screen and (max-width: 1436px) {
  .swiper-slide-active {
    padding: 0 80px !important;
  }

  .swiper-button-prev {
    left: 76px;
  }

  .swiper-button-next {
    right: 102px;
  }
}

@media screen and (max-width: 1357px) {
  .swiper-button-prev {
    left: 69px;
  }

  .swiper-button-next {
    right: 89px;
  }
}

@media screen and (max-width: 1254px) {
  #Section5 .contents {
    width: 350px;
  }

  #Section5 .title {
    padding: 95px 0 130px 0;
  }
}

@media screen and (max-width: 1143px) {
  .web {
    display: none;
  }

  .image-gallery {
    display: block;
  }

  #Section5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  #Section5 .title {
    padding: 0;
    width: 65%;
  }

  #Section5 .contents {
    width: 300px;
  }

  .info {
    font-size: 20px !important;
  }

  /* mobile slide */
  .image-gallery-slide-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-gallery-swipe {
    width: 500px;
  }

  .image-gallery-thumbnail {
    width: 20% !important;
  }

  .image-gallery-slides {
    height: 100%;
  }

  .image-gallery-thumbnails {
    margin-top: 10px;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(50vh);
  }

  .image-gallery-description {
    width: 100%;
    position: relative !important;
    left: 0 !important;
    bottom: 0 !important;
    color: #fff;
    font-size: 27px;
    line-height: 1.2 !important;
    text-align: start;
    left: 40%;
    background: none !important;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus,
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 3px solid #1aee88;
  }

  .image_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .mobile_date {
    padding: 10px 15px;
    text-align: left;
    width: 100%;
    color: #1aee88;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  #Section5 {
    justify-content: center;
    padding: 0 50px;
  }

  #Section5 .title {
    padding-bottom: 10px;
  }

  .image-gallery-thumbnail {
    width: 33.333% !important;
  }

  .image-gallery-swipe {
    width: 100%;
    height: 100%;
  }

  .image-gallery-description {
    font-size: 18px !important;
    text-align: center;
    letter-spacing: 0.36px;
  }

  .mobile_date {
    font-size: 11px;
    text-align: center;
  }
}

@media screen and (max-width: 629px) {
  .image-gallery-description,
  .mobile_date {
    text-align: left;
  }
}
