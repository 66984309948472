#Left {
  border-right: 1px solid #fff;
  position: absolute;
  width: 50px;
  left: 0;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
  opacity: 0.1;
}
