#Section6 {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #0d0d0d;
  padding-left: 360px;
  transition: 0.5s;
}

#Section6 .category {
  padding: 50px 0 35px 0;
  transition: 0.5s;
}

#Section6 .contents {
  width: 100%;
  font-size: 155px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 0.5s;
}

#Section6 .contents a {
  cursor: pointer;
  transition: 0.5s;
}

/* text animation */
#Section6 .fromLeft {
  background: url("/images/section6/basic_back.jpg");
  background-size: 100% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  color: rgba(0, 0, 0, 0.0001);
  display: inline-block;
  margin: 0;
}

#Section6 .fromLeft:after {
  display: block;
  content: "";
  border-bottom: solid 2px #6f2cff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 100%;
}

#Section6 .fromLeft:hover:after {
  transform: scaleX(1);
}

#Section6 .fromLeft:hover {
  background: url(/images/section6/gradient_back.jpg);
  background-size: 100% 100%;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
}

/* quick sns,name,top */
#Section6 .name {
  width: 43px;
  position: absolute;
  left: 28px;
  z-index: 2;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
}

#Section6 .sns {
  position: absolute;
  right: 34px;
  z-index: 2;
  cursor: pointer;
}

#Section6 .sns div {
  width: 43px;
  height: 43px;
  margin-bottom: 10px;
}

#Section6 .sns div > img {
  width: 100%;
}

#Section6 .name,
#Section6 .sns {
  display: none;
}

#Section6 .top {
  width: 87px;
  height: 87px;
  transform: rotate(90deg);
  cursor: pointer;
  background: url(/images/section6/arrow.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
}

#Section6 .top:hover {
  background: url(/images/section6/arrow_green.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#Section6 .mobile {
  display: none;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: 1649px) {
  #Section6 {
    padding-left: 300px;
  }
}

@media screen and (max-width: 1555px) {
  #Section6 {
    padding-left: 250px;
  }
}

@media screen and (max-width: 1400px) {
  #Section6 {
    padding-left: 200px;
  }
}

@media screen and (max-width: 1300px) {
  #Section6 {
    padding-left: 120px;
  }
}

@media screen and (max-width: 1183px) {
  #Section6 .contents {
    font-size: 100px;
  }

  #Section6 .mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 30px;
    color: #fff;
  }

  #Section6 .mobile .copyright {
    font-size: 10px;
    padding-top: 30px;
    display: none;
  }
}

@media screen and (max-width: 857px) {
  #Section6 .contents {
    font-size: 80px;
  }
}

@media screen and (max-width: 768px) {
  #Section6 {
    padding-left: 50px;
  }

  #Section6 .category {
    width: 100%;
    padding: 80px 0 53px 0;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #Section6 .category > div {
    width: 80%;
  }

  #Section6 .contents {
    font-size: 48.5px;
    padding: 150px 0;
  }

  /* quick sns, name */
  #Section6 .name,
  #Section6 .sns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 120px;
    bottom: 65px;
  }

  #Section6 .name {
    font-size: 15px;
  }

  #Section6 .sns div {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
  }

  #Section6 .mobile .copyright {
    display: block;
  }

  /* text animation */
  #Section6 .fromLeft {
    display: inline;
  }

  #Section6 .fromLeft:after {
    display: none;
  }
}

@media screen and (max-height: 568px) {
  #Section6 .contents {
    font-size: 35px;
  }
}
