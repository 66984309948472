#Section3 {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Section3 .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

#Section3 .info .title {
  padding-bottom: 59px;
}

#Section3 .info .sub {
  font-size: 25px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  #Section3 .info .title {
    padding-bottom: 40px;
  }

  #Section3 .info .sub {
    font-size: 16px;
  }
}
