@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

#Write {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
  z-index: 1;
}

#svg {
  width: 403px;
}

#mmd {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 0.5s linear forwards;
  stroke-linecap: round;
  animation-delay: 1.3s;
  stroke: #1aee88;
  fill: none;
  stroke-width: 8;
}

#core {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: dash 0.5s linear forwards;
  stroke-linecap: round;
  animation-delay: 1.7s;
  stroke: #1aee88;
  fill: none;
  stroke-width: 8;
}

@media screen and (max-width: 768px) {
  #svg {
    width: 273px;
  }
}
