#Section1 {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Section1 .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Section1 .info .title {
  padding-bottom: 59px;
  z-index: 2;
}

#Section1 .info .sub {
  font-size: 25px;
  text-align: center;
  color: #fff;
  z-index: 2;
}

#Section1 .name {
  width: 43px;
  position: absolute;
  left: 28px;
  z-index: 2;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: rgba(255, 255, 255, 0.9);
  font-size: 20px;
}

#Section1 .sns {
  position: absolute;
  right: 34px;
  z-index: 2;
  cursor: pointer;
}

#Section1 .sns div {
  width: 43px;
  height: 43px;
  margin-bottom: 10px;
}

#Section1 .sns div > img {
  width: 100%;
}

#Section1 .name,
#Section1 .sns {
  display: none;
}

@media screen and (max-width: 768px) {
  #Section1 .name,
  #Section1 .sns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 120px;
  }

  #Section1 .info .title {
    padding-bottom: 40px;
  }

  #Section1 .info .sub {
    font-size: 16px;
  }

  #Section1 .name {
    font-size: 15px;
  }

  #Section1 .name,
  #Section1 .sns {
    bottom: 30px;
  }

  #Section1 .sns div {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
  }
}
