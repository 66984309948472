#Footer {
  height: 243px;
  font-size: 18px;
  letter-spacing: -0.54px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #0d0d0d;
  position: relative;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  #Footer {
    height: 25px;
    font-size: 10px;
  }
}
